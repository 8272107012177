import React from "react";
import { optionsMenu } from "../../utils";
import { IBox } from "./box.structure";
import * as Styles from "./styles";

const Box = ({ valueBox, transactions }: IBox) => {
  const message = "Nada para exibir";

  const mapOptionsMenu = React.useMemo(() => {
    return optionsMenu.map((item, index) => {
      return <Styles.BoxMenuContent key={index}>{item}</Styles.BoxMenuContent>;
    });
  }, [optionsMenu]);

  return (
    <Styles.MainContent>
      <Styles.BoxContent>{mapOptionsMenu}</Styles.BoxContent>

      {transactions.length > 0 ? (
        <Styles.BoxValueContent>{valueBox}</Styles.BoxValueContent>
      ) : (
        <Styles.MessageContent>
          <Styles.MessageText>{message}</Styles.MessageText>
        </Styles.MessageContent>
      )}
    </Styles.MainContent>
  );
};

export default Box;
