
import React from "react";
import { IComplianceContext, IProps } from "./complianceContext.structure";

export const ComplianceContext = React.createContext({} as IComplianceContext);

const ComplianceContextProvider: React.FC<IProps> = ({ children }) => {
  const [reload, setReload] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<string>("OPEN");
  const [flag, setFlag] = React.useState<string>("");
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [motivation, setMotivation] = React.useState<string>("");
  const [pageNumber, setPageNumber] = React.useState<number>(0);

  return (
    <ComplianceContext.Provider
      value={React.useMemo(
        () => ({
          reload,
          setReload,
          status,
          setStatus,
          currentPage,
          setCurrentPage,
          flag,
          setFlag,
          tabValue,
          setTabValue,
          motivation,
          setMotivation,
          pageNumber,
          setPageNumber,
        }),
        [
          reload,
          setReload,
          status,
          setStatus,
          currentPage,
          setCurrentPage,
          flag,
          setFlag,
          tabValue,
          setTabValue,
          motivation,
          setMotivation,
          pageNumber,
          setPageNumber,
        ]
      )}
    >
      {children}
    </ComplianceContext.Provider>
  );
};

export default ComplianceContextProvider;
