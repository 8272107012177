import React from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IPagination } from "./pagination.structure";
import * as Styles from "./styles";

const Pagination = ({ total, offset, setOffset }: IPagination): JSX.Element => {
  const MAX_ITEMS = total < 5 ? total : 5;
  const maxFirst = Math.max(total - (MAX_ITEMS - 1), 1);
  const first = Math.min(Math.max(offset - 1, 1), maxFirst);

  const setCurrentInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = parseInt(e.target.value, 10);
    if (value > 0 && value <= total) {
      setOffset(value);
    }
  };

  const Pages = React.useMemo(() => {
    return Array.from({ length: Math.min(MAX_ITEMS, 7) })
      .map((_, index) => index + first)
      .map((page) => {
        return (
          <div key={page}>
            <Styles.PageButton
              onClick={() => {
                setOffset(page);
              }}
              active={page === offset}
            >
              {page}
            </Styles.PageButton>
          </div>
        );
      });
  }, []);

  return (
    <Styles.PaginationContent>
      <div>
        <Styles.ArrowButton
          onClick={() => setOffset(offset - 1)}
          disabled={offset <= 1}
        >
          <IoIosArrowBack style={{ width: 20, height: 20 }} />
        </Styles.ArrowButton>
      </div>
      {Pages}
      {!(total - offset <= 3) && (
        <>
          <span>...</span>
          <Styles.LastPageButton
            onClick={() => {
              setOffset(total);
            }}
            active={total === offset}
          >
            {total}
          </Styles.LastPageButton>
        </>
      )}

      <div>
        <Styles.ArrowButton
          onClick={() => setOffset(offset + 1)}
          disabled={offset === total}
        >
          <IoIosArrowForward style={{ width: 20, height: 20 }} />
        </Styles.ArrowButton>

        <Styles.NumberInput
          onChange={(event) => setCurrentInput(event)}
          maxLength={3}
        />
      </div>
    </Styles.PaginationContent>
  );
};

export default Pagination;
