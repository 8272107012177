import { IInputDate } from "./inputDate.structure";
import * as Styles from "./styles";

const InputDate = ({
  valueInitialDate,
  onChangeInitialDate,
  valueLastDate,
  onChangeLastDate,
}) => {
  return (
    <Styles.DateContainer>
      <Styles.DateSubContainer>
        <Styles.DateLabel htmlFor="de">De</Styles.DateLabel>
        <Styles.DateInput
          type="date"
          id="de"
          name="de"
          required
          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
          value={valueInitialDate}
          onChange={onChangeInitialDate}
        />
      </Styles.DateSubContainer>

      <Styles.DateSubContainer>
        <Styles.DateLabel htmlFor="ate">Até</Styles.DateLabel>
        <Styles.DateInput
          type="date"
          id="ate"
          name="ate"
          required
          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
          value={valueLastDate}
          onChange={onChangeLastDate}
        />
      </Styles.DateSubContainer>
    </Styles.DateContainer>
  );
};

export default InputDate;
