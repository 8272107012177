import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ComplianceContextProvider from "./context/complianceContext";
import Alerts from "./pages/Alerts";
import api from "./services/api";
import LoginService from "./services/login";
import { mockedRoutes } from "./utils";

interface IUserInfo {
  id: string;
  token: string;
  refresh_token: string;
}

export default function Root(props) {
  const env_type = process.env.ENV_TYPE;
  const [userInfo, setUserInfo] = React.useState(
    props?.customProps?.auth_token || ({} as IUserInfo)
  );
  const routes =
    env_type === "local" ? mockedRoutes : props?.customProps?.routes;

  const localLogin = React.useCallback(async () => {
    const email = process.env.LOCAL_ENV_USER;
    const password = process.env.LOCAL_ENV_PASSWORD;

    try {
      const localToken = await LoginService.login(email, password);
      const { token, refresh_token, id } = localToken;
      setUserInfo({ token, refresh_token, id });
    } catch (error) {
      return false;
    }
  }, []);

  React.useEffect(() => {
    document.title = "Admin | Compliance - Alertas";
  }, []);

  React.useLayoutEffect(() => {
    if (env_type === "local") {
      localLogin();
    }

    if (env_type !== "local") {
      if (!userInfo.token) {
        window.location.href = "/";
        return;
      }
    }

    api.defaults.headers.common["Authorization"] = `bearer ${userInfo.token}`;
    api.defaults.headers.common["refresh_token"] = userInfo.refresh_token;
  }, [userInfo]);

  return (
    <BrowserRouter>
      <ComplianceContextProvider>
        <Routes>
          <Route path={routes.compliance.alerts} element={<Alerts />} />
        </Routes>
      </ComplianceContextProvider>
    </BrowserRouter>
  );
}
