import styled from "styled-components";

export const tableContent = styled.button`
  margin-top: 8px;
  outline: none;
  border: none;
`;

export const TR = styled.tr`
  display: flex;
  flex-direction: row;
  height:9vh;
  background: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: 1400px) {
    height: 17vh;
  }
`;

export const TableText = styled.td`
  font-size: 0.875rem;
  color: var(--gray4);
  width: 4%;

  @media only screen and (max-width: 1400px) {
    width: 6%;
  }
`;

export const TableTextName = styled.td`
  font-size: 0.875rem;
  width: 16%;
  color: var(--gray4);
  text-align: center;
  padding-left: 90px;

  @media only screen and (max-width: 1400px) {
    width: 15%;
    padding-left: 60px;
  }
`;

export const TableTextDate = styled.td`
  font-size: 0.875rem;
  color: var(--gray4);
  width: 15%;

  @media only screen and (max-width: 1400px) {
    width: 16%;
  }
`;

export const TableTextDocument = styled.td`
  font-size: 0.875rem;
  color: var(--gray4);
  width: 14%;
  padding-left: 8px;

  @media only screen and (max-width: 1400px) {
    width: 15%;
    padding-left: 15px;
  }
`;

export const TableTextAccount = styled.td`
  font-size: 0.875rem;
  color: var(--gray4);
  width: 10%;

  @media only screen and (max-width: 1400px) {
    width: 9%;
  }
`;

export const TableTextClassify = styled.td`
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--gray4);
  width: 14%;
  padding-left: 60px;
`;

export const TableTextHigh = styled.td`
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--error);
  width: 14%;
  padding-left: 60px;
`;

export const TableTextMedium = styled.td`
  font-size: 0.875rem;
  font-weight: 700;
  color: #fcbe07;
  width: 14%;
  padding-left: 60px;
`;

export const TableTextLow = styled.td`
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--success);
  width: 14%;
  padding-left: 60px;
`;

export const AlertHigh = styled.td`
  display: flex;
  background: none;
  width: 6%;
  height: 1rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid var(--error);
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: var(--error);
  margin-left: 60px;
  @media only screen and (max-width: 1400px) {
    width: 10%;
    margin-left: 20px;
  }
`;

export const AlertMedium = styled.td`
  display: flex;
  background: none;
  width: 6%;
  height: 1rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid #fcbe07;
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: #fcbe07;
  margin-left: 60px;
  @media only screen and (max-width: 1400px) {
    width: 10%;
    margin-left: 20px;
  }
`;

export const AlertLow = styled.td`
  display: flex;
  background: none;
  width: 6%;
  height: 1rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid var(--success);
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: var(--success);
  margin-left: 60px;
  @media only screen and (max-width: 1400px) {
    width: 10%;
    margin-left: 20px;
  }
`;

export const IconContainer = styled.div`
  width: 50px;
  align-items: center;
  margin-left: 190px;
  color: var(--gray4);
  @media only screen and (max-width: 1400px) {
    margin-left: 80px;
  }
`;

export const AlertModalContainer = styled.div`
  display: flex;
  width: 90%;
  flex-flow: column nowrap;
`;

export const UserModalContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 16.25rem;
`;

export const UserItemsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
`;

export const UserModalTitle = styled.span`
  display: flex;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--gray4);
`;

export const UserModalText = styled.span`
  display: flex;
  font-size: 0.875rem;
  color: var(--gray4);
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 92%;
  height: 100%;
  gap: 15px;
  margin-top: 8px;
`;

export const BlueButton = styled.button`
  width: 140px;
  height: 100%;
  padding: 8px;
  border-radius: 6px;
  background: var(--gray3);
  border: none;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  cursor: default;
`;

export const RedButton = styled.button`
  width: 140px;
  height: 100%;
  padding: 8px;
  border-radius: 6px;
  background: var(--gray3);
  border: none;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  cursor: default;
`;

export const RulesModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
`;

export const RulesModalSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;
export const RulesTitleModal = styled.span`
  display: flex;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--gray4);
`;

export const RulesTextModal = styled.span`
  display: flex;
  font-size: 1rem;
  color: var(--error);
`;

export const DateTextModal = styled.span`
  display: flex;
  font-size: 1rem;
  color: var(--gray4);
`;

export const BoxModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 0.5rem;
`;

export const BoxMenuContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--gray4);
  font-size: 0.875rem;
  margin-left: 5px;
  width: 20%;
`;

export const BoxMoneyIn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--success);
  font-size: 0.875rem;
  width: 23%;
`;

export const BoxMoneyOut = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 5px;
  align-items: center;
  color: var(--error);
  font-size: 0.875rem;
  width: 23%;
`;

export const BoxMenuContentRecipient = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.875rem;
  width: 20%;
  padding-left: 35px;
`;

export const BoxMenuContentDescription = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 5px;
  align-items: center;
  color: var(--gray4);
  font-size: 0.875rem;
  width: 22%;
`;

export const BoxMenuContentBalanceType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--gray4);
  font-size: 0.875rem;
  width: 16%;
`;

export const TransactionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`;

export const GreenButton = styled.button`
  width: 130px;
  height: 100%;
  padding: 8px;
  border-radius: 6px;
  background: var(--success);
  border: none;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  cursor: pointer;
`;

export const GreenButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const AnalyserContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.875rem;
  color: var(--primary);
  cursor: pointer;
`;
export const AnalyserContainerDisabled = styled.div`
  display: flex;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
`;
