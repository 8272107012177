import React from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import Box from "../Box";
import ModalTable from "../ModalTable";
import * as Styles from "./styles";
import { ITable } from "./table.structure";
import * as DateFns from "date-fns";
import { replaceCnpj, replaceCpf } from "../../helpers/masks";
interface IHashMapModalTableHashMap {
  [key: number]: React.ReactNode;
}
interface ITableHashMap {
  [key: string]: React.ReactNode;
}

const Table = ({
  TableValue,
  onClick,
  hashTable,
  closeButton,
  onClickButtonIgnore,
  onClickToMonitor,
  onClickBlock,
  setId,
  setMotive,
  onClickBeneficiary,
  setNameBeneficiary,
  setDocumentBeneficiary,
  setBankBeneficiary,
  setBranchBeneficiary,
  setAccountBeneficiary,
  setAnalyser,
  setResolvedAt,
  setStatusIngored,
  onClickButtonAnalyser,
}: ITable) => {
  const [name, setName] = React.useState<string>("");
  const [bank, setBank] = React.useState<string>("");
  const [statusTable, setStatusTable] = React.useState<string>("");
  const [document, setDocument] = React.useState<string>("");
  const [account, setAccount] = React.useState<string>("");
  const [analyserTable, setAnalyserTable] = React.useState<string>("");
  const [resolvedAtTable, setResolvedAtTable] = React.useState<string>("");
  const [dateHours, setDateHours] = React.useState<any>();
  const [rules, setRules] = React.useState<string>("");
  const [transactions, setTransactions] = React.useState([]);

  const TableClassifyHashMap: ITableHashMap = {
    HIGH: <Styles.TableTextHigh>Muito Alto</Styles.TableTextHigh>,
    MEDIUM: <Styles.TableTextMedium>Média</Styles.TableTextMedium>,
    LOW: <Styles.TableTextLow>Baixa</Styles.TableTextLow>,
  };

  const TableAlertHashMap: ITableHashMap = {
    HIGH: <Styles.AlertHigh>Alta</Styles.AlertHigh>,
    MEDIUM: <Styles.AlertMedium>Media</Styles.AlertMedium>,
    LOW: <Styles.AlertLow>Baixa</Styles.AlertLow>,
  };

  const mapOptionsSubMenu = React.useMemo(() => {
    return TableValue.map((item, index) => {
      return (
        <Styles.tableContent
          key={index}
          onClick={() => {
            onClick();
            setStatusIngored(item.status);
            setStatusTable(item.status);
            setAnalyser(item.analyzer);
            setAnalyserTable(item.analyzer);
            setResolvedAt(item.resolvedAt);
            setResolvedAtTable(item.resolvedAt);
            setId(item.id);
            setMotive(item.analysisDetails);
            setTransactions(item.Transactions);
            setName(item.Account.userName);
            setBank(item.Account.clientName);
            setDocument(item.Account.userDocument);
            setAccount(item.Account.bankAccount);
            setRules(item.description);
            setDateHours(
              DateFns.format(new Date(item.createdAt), "dd/MM/yyyy HH:mm")
            );
          }}
        >
          <Styles.TR>
            <Styles.TableText>{item.Account.clientName}</Styles.TableText>

            <Styles.TableTextName>{item.Account.userName}</Styles.TableTextName>
            {item.Account.classify === null ? (
              <Styles.TableTextClassify> - </Styles.TableTextClassify>
            ) : (
              TableClassifyHashMap[item.flag]
            )}

            <Styles.TableTextDocument>
              {item.Account.userDocument.length === 11
                ? replaceCpf(item.Account.userDocument)
                : replaceCnpj(item.Account.userDocument)}
            </Styles.TableTextDocument>

            <Styles.TableTextAccount>
              {item.Account.bankAccount}
            </Styles.TableTextAccount>
            <Styles.TableTextDate>
              {DateFns.format(new Date(item.createdAt), "dd/MM/yyyy")}
            </Styles.TableTextDate>

            {TableAlertHashMap[item.flag]}

            <Styles.IconContainer>
              <IoIosArrowDropdownCircle size={28} />
            </Styles.IconContainer>
          </Styles.TR>
        </Styles.tableContent>
      );
    });
  }, [TableValue]);

  const mapTransaction = React.useMemo(() => {
    return transactions.map((item, index) => {
      return (
        <Styles.TransactionsContainer key={index}>
          <Styles.BoxMenuContent>
            {DateFns.format(new Date(item.balanceDate), "dd/MM/yyyy HH:mm")}
          </Styles.BoxMenuContent>
          {item.credit === "false" ? (
            <Styles.BoxMoneyIn>
              +{" "}
              {item.amount.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Styles.BoxMoneyIn>
          ) : (
            <Styles.BoxMoneyOut>
              -{" "}
              {item.amount.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Styles.BoxMoneyOut>
          )}

          <Styles.BoxMenuContentBalanceType>
            {item.balanceType}
          </Styles.BoxMenuContentBalanceType>
          <Styles.BoxMenuContentDescription>
            {item.description}
          </Styles.BoxMenuContentDescription>
          <Styles.BoxMenuContentRecipient
            onClick={() => {
              onClickBeneficiary();
              setNameBeneficiary(item.name);
              setDocumentBeneficiary(item.document);
              setBankBeneficiary(item.bank);
              setBranchBeneficiary(item.branch);
              setAccountBeneficiary(item.account);
            }}
          >
            {item.name}
          </Styles.BoxMenuContentRecipient>
        </Styles.TransactionsContainer>
      );
    });
  }, [transactions]);

  const ModalTableHashMap: IHashMapModalTableHashMap = {
    1: (
      <ModalTable
        closeButton={closeButton}
        title="Detalhes da Conta"
        content={
          <Styles.AlertModalContainer>
            <Styles.UserModalContainer>
              <Styles.UserItemsModalContainer>
                <Styles.UserModalTitle>{name}</Styles.UserModalTitle>
                <Styles.UserModalText>Banco: {bank}</Styles.UserModalText>
                <Styles.UserModalText>
                  {document.length === 11 ? "CPF: " : "CNPJ: "}
                  {document.length === 11
                    ? replaceCpf(document)
                    : replaceCnpj(document)}
                </Styles.UserModalText>
                <Styles.UserModalText>Conta: {account}</Styles.UserModalText>
                <Styles.ButtonContent>
                  {statusTable !== "IGNORED" && (
                    <>
                      <Styles.BlueButton
                        onClick={onClickToMonitor}
                        disabled={true}
                      >
                        Monitorar conta
                      </Styles.BlueButton>
                      <Styles.RedButton onClick={onClickBlock} disabled={true}>
                        Bloquear conta
                      </Styles.RedButton>
                    </>
                  )}
                </Styles.ButtonContent>
              </Styles.UserItemsModalContainer>

              <Styles.RulesModalContainer>
                <Styles.RulesTitleModal>Regra</Styles.RulesTitleModal>
                <Styles.RulesModalSubContainer>
                  <Styles.RulesTextModal>{rules}</Styles.RulesTextModal>
                  <Styles.DateTextModal>{dateHours}</Styles.DateTextModal>
                </Styles.RulesModalSubContainer>
              </Styles.RulesModalContainer>

              <Styles.BoxModalContainer>
                <Box valueBox={mapTransaction} transactions={transactions} />
                <Styles.FooterContainer>
                  {statusTable === "IGNORED" ? (
                    <Styles.AnalyserContainer
                      onClick={() => onClickButtonAnalyser()}
                    >
                      {analyserTable} -{" "}
                      {resolvedAtTable
                        ? DateFns.format(
                            new Date(resolvedAtTable),
                            "dd/MM/yyyy"
                          )
                        : null}
                    </Styles.AnalyserContainer>
                  ) : (
                    <Styles.AnalyserContainerDisabled />
                  )}

                  {transactions.length > 0 && (
                    <Styles.GreenButtonContainer>
                      {statusTable !== "IGNORED" && (
                        <Styles.GreenButton
                          onClick={() => onClickButtonIgnore()}
                        >
                          Ignorar alerta
                        </Styles.GreenButton>
                      )}
                    </Styles.GreenButtonContainer>
                  )}
                </Styles.FooterContainer>
              </Styles.BoxModalContainer>
            </Styles.UserModalContainer>
          </Styles.AlertModalContainer>
        }
      />
    ),
  };

  return (
    <>
      {mapOptionsSubMenu}
      {ModalTableHashMap[hashTable]}
    </>
  );
};

export default Table;
