import axios from "axios";

const base_url = process.env.URL_LOGIN;

class LoginService {
  async login(email: string, password: string) {
    const body = {
      email,
      password,
    };

    try {
      const { data } = await axios.post(`${base_url}/auth/login`, body);
      return data;
    } catch (error) {
      return false;
    }
  }
}

export default new LoginService();
