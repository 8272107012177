import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  width: 100%;
`;

export const BlocTabs = styled.div`
  display: flex;
  gap: 15px;
  
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    width: 87%;
  }
`;

export const RenderBadgeValuContainer = styled.div`
  display: flex;
  margin-right: 20px;
`;

export const TabsButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 0.9em;
  color: ${(props) => (props.activeColor ? "var(--primary)" : "var(--gray5)")};
  border-bottom: ${(props) =>
    props.active ? "2px solid var(--primary)" : null};
  margin-bottom: 10px;
`;

export const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 105%;
`;

export const TabsMenuContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8vh;
  margin-bottom: 10px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  @media only screen and (max-width: 1400px) {
    height: 10vh;
  }
`;

export const TextMenuTabs = styled.span`
  font-size: 1em;
  font-weight: 700;
  color: var(--gray4);
  width: 40%;
  padding-left: 15px;
  padding-right: 10px;
`;
