export const filterTypes = [
  {
    value: "document",
    label: "CPF/CNPJ",
  },
  // {
  //   value: "account",
  //   label: "Conta",
  // },
  // {
  //   value: "bank",
  //   label: "Banco",
  // },
  // {
  //   value: "user",
  //   label: "Usuário",
  // },
];

export const optionsSubMenu = [
  {
    id: "banco",
    value: "Qesh Bank",
  },
  {
    id: "usuario",
    value: "JULIA MOREIRA",
  },
  {
    id: "risco",
    value: "Muito alto",
  },
  {
    id: "documento",
    value: "123.456.789-10",
  },
  {
    id: "conta",
    value: "340028922",
  },
  {
    id: "data",
    value: "30/12/2021",
  },
  {
    id: "prioridade",
    value: "Alta",
  },
  {
    id: "prioridade",
    value: "Alta",
  },
];

export const valueMenu = [
  {
    id: "data",
    value: "30/12/2021",
  },

  {
    id: "valor",
    value: "R$ 100,00",
    inout: "OUT",
  },
  {
    id: "tipo",
    value: "TED",
  },
  {
    id: "descricao",
    value: "TED para fulana",
  },
  {
    id: "beneficiario",
    value: "Fulana",
  },
];

export const optionsMenu = [
  "Data",
  "Valor",
  "Tipo",
  "Descrição",
  "Beneficiário",
];

export const optionsTabsButton = [
  {
    active: 0,
    title: "Alertas",
  },
  {
    active: 1,
    title: "Em monitoramento",
  },
  {
    active: 2,
    title: "Ignorados",
  },
  {
    active: 3,
    title: "Bloqueados",
  },
];

export const optionsTabsMenu = [
  "Banco",
  "Usuário",
  "Grau de risco",
  "Documento",
  "Conta",
  "Data",
  "Prioridade",
  "",
];

interface IRoutes {
  compliance: {
    alerts: string;
  };
}

export const mockedRoutes: IRoutes = {
  compliance: {
    alerts: "/hub/compliance-alerts",
  },
};