import styled from "styled-components";

export const InputSelect = styled.select`
  width: 250px;
  padding: 15px 5px;
  border: 1px solid var(--gray2);
  border-radius: 0.3rem;
  font-size: 1em;
  color: var(--gray4);
  font-weight: 400;
  &:focus {
    outline: 1px solid var(--primary);
  }
  @media only screen and (max-width: 1024px) {
    width: 200px;
  }
`;
