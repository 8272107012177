import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: var(--gray1);
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-size: 1.3em;
  font-weight: 700;
  color: var(--gray5);
`;
export const SubTitle = styled.span`
  font-size: 1.5em;
  font-weight: 700;
  color: var(--gray5);
`;

export const InputContainer = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 2.188rem;
  align-items: center;
  justify-content: space-between;
`;

export const InputSubContainer = styled.section`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  @media only screen and (max-width: 1024px) {
    gap: 0.5rem;
  }
`;

export const ButtonClearFilterContainer = styled.div`
  margin-left: 1.563rem;
`;

export const TitleSubContainer = styled.div`
  display: flex;
  width: 88%;
  justify-content: space-between;
`;

export const DataContainer = styled.div`
  display: flex;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

export const TextAccordion = styled.span`
  font-size: 0.9em;
  font-weight: 400;
  color: var(--gray4);
`;

export const BoxMenuValueContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 14%;
  height: 10vh;
  padding: 0 0.313rem;
  align-items: center;
  color: var(--gray4);
  font-size: 1em;
  font-weight: 400;
`;

export const PaginationContent = styled.div`
  display: flex;
  position: relative;
  align-self: center;
  bottom: 0;
  margin-bottom: 8px;
  margin-top: 10px;
  @media only screen and (max-width: 1400px) {
    margin-bottom: 10px;
  }
`;

export const BoxInputs = styled.div`
  width: 100%;
  max-width: 34.375rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.438rem;
`;

export const InputAreaContainer = styled.div`
  width: 100%;
  height: 16.25rem;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 0.938rem;
  border-radius: 0.75rem;
  background-color: var(--gray1);
`;
export const AnalyserContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.875rem;
`;

export const InputArea = styled.textarea`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 90%;
  display: flex;
  border-radius: 0.75rem;
  background-color: var(--gray1);
  border: none;
  outline: none;
  padding: 0.625rem;
  font-size: 1em;
  color: var(--gray4);
`;

export const Button = styled.button`
  display: flex;
  width: 20%;
  padding: 0.625rem;
  align-self: flex-end;
  justify-content: center;
  border: none;
  border-radius: 0.5rem;
  background: ${(props) => (props.active ? "var(--gray3)" : "var(--success)")};
  color: #ffffff;
  cursor: ${(props) => (props.active ? "default" : "pointer")};
`;

export const ButtonBloc = styled.button`
  display: flex;
  width: 20%;
  padding: 0.625rem;
  align-self: flex-end;
  justify-content: center;
  margin-top: 0.313rem;
  border: none;
  border-radius: 0.5rem;
  background: var(--error);
  color: #ffffff;
  cursor: pointer;
`;

export const OutText = styled.span`
  color: var(--error);
`;
export const InText = styled.span`
  color: var(--success);
`;

export const AccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const AccordionContentHash = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ContainerLoading = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
`;

export const BoxInputsBeneficiary = styled.div`
  width: 100%;
  max-width: 30.5rem;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 10px;
`;

export const InputAreaContainerBeneficiary = styled.div`
  width: 100%;
  height: 16.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TextContainerBeneficiary = styled.div`
  width: 100%;
  display: flex;
  gap: 0.4rem;
`;

export const TitleBeneficiary = styled.span`
  display: flex;
  color: var(--gray4);
  font-weight: 700;
  font-size: 1rem;
`;
export const SubTitleBeneficiary = styled.span`
  display: flex;
  width: 100%;
  color: var(--gray4);
  font-size: 1rem;
`;

export const ButtonContainerBeneficiary = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
`;

export const ButtonBeneficiary = styled.button`
  display: flex;
  width: 50%;
  justify-content: center;
  align-self: center;
  padding: 0.625rem;
  margin-top: 0.313rem;
  border: none;
  border-radius: 0.5rem;
  background: var(--primary);
  color: #ffffff;
  cursor: pointer;
`;

export const BoxInputsInfo = styled.div`
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 10px;
`;

export const InputAreaContainerInfo = styled.div`
  width: 100%;
  height: 16.25rem;
  display: flex;
  flex-direction: column;
`;

export const TextContainerInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
`;

export const TitleInfo = styled.p`
  display: flex;
  color: var(--gray4);
  font-size: 1rem;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const TitleBoldInfo = styled.p`
  color: var(--gray4);
  font-weight: 700;
  font-size: 1rem;
  margin: 0 5px 0 5px;
`;
export const SubTitleInfo = styled.span`
  display: flex;
  width: 100%;
  color: var(--gray4);
  font-size: 1rem;
`;

export const ButtonContainerInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 50px;
`;

export const ButtonInfo = styled.button`
  display: flex;
  width: 50%;
  justify-content: center;
  align-self: center;
  padding: 0.8rem;
  margin-top: 0.313rem;
  border: none;
  border-radius: 0.5rem;
  background: var(--primary);
  color: #ffffff;
  cursor: pointer;
`;
