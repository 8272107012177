import { RiCloseFill } from "react-icons/ri";
import { IModal } from "../Modal/modal.structure";
import * as Styles from "./styles";

const ModalBeneficiary = ({ title, content, closeButton }: IModal) => {
  return (
    <Styles.Container>
      <Styles.Modal>
        <Styles.Header>
          <Styles.Title>{title}</Styles.Title>
          <Styles.CloseButton onClick={closeButton}>
            <RiCloseFill />
          </Styles.CloseButton>
        </Styles.Header>

        {content}
      </Styles.Modal>
    </Styles.Container>
  );
};

export default ModalBeneficiary;
