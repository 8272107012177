import { IInputDropdown } from "./inputDropdown.structure";
import * as Styles from "./styles";

const InputDropDown = ({ content, onChange, value }: IInputDropdown) => {
  return (
    <div>
      <Styles.InputSelect value={value} onChange={onChange}>{content}</Styles.InputSelect>
    </div>
  );
};

export default InputDropDown;
