import React from "react";
import renderFlags from "../RenderFlags";
import { IRenderBadgeValue } from "./renderBadgeValue.structure";
import * as Styles from "./styles";

const RenderBadgeValue = ({
  alertHigh,
  alertMedium,
  alertLow,
  onClickBadgeHigh,
  onClickBadgeMedium,
  onClickBadgeLow,
  selected,
}: IRenderBadgeValue) => {
  const [lowValue, setLowValue] = React.useState<number>(alertLow);
  const [mediumValue, setMediumValue] = React.useState<number>(alertMedium);
  const [highValue, setHighValue] = React.useState<number>(alertHigh);

  const flagsType = [
    {
      name: "HIGH",
      value: highValue,
      onClick: onClickBadgeHigh,
    },
    {
      name: "MEDIUM",
      value: mediumValue,
      onClick: onClickBadgeMedium,
    },
    {
      name: "LOW",
      value: lowValue,
      onClick: onClickBadgeLow,
    },
  ];

  const flagsRender = React.useMemo(() => {
    return flagsType.map(({ name, value, onClick }) => {
      return (
        <Styles.BadgeHighContainer
          key={name}
          onClick={onClick}
          isDisabled={selected !== name}
        >
          {renderFlags(name, value)}
        </Styles.BadgeHighContainer>
      );
    });
  }, [flagsType]);

  return <Styles.AlertContainer>{flagsRender}</Styles.AlertContainer>;
};

export default RenderBadgeValue;
