import styled from "styled-components";

export const PaginationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;

export const ArrowButton = styled.button`
  border: none;
  height: fit-content;
  background: transparent;
  cursor: pointer;
`;

export const PageButton = styled.button<{ active: boolean }>`
  font-size: 0.8rem;
  font-weight: 700;
  height: 32px;
  width: 26px;
  border: none;
  background: none;
  border-bottom: ${(props) =>
    props.active ? "1px solid var(--primary)" : "transparent"};
  outline: none;
  align-items: center;
  color: ${(props) => (props.active ? "var(--primary)" : "var(--gray5)")};
  cursor: pointer;
  margin-right: 10px;
  :hover {
    color: var(--primary);
  }
`;

export const LastPageButton = styled.button<{ active: boolean }>`
  font-size: 0.8rem;
  font-weight: 700;
  border: none;
  background: transparent;
  color: ${(props) => (props.active ? "var(--primary)" : "var(--gray5)")};
  border-bottom: ${(props) =>
    props.active ? ` 1px solid var(--primary)` : null};
  cursor: pointer;
  margin-top: 1px;
  margin-left: 5px;
`;

export const InputContent = styled.div`
  display: flex;
  width: 100%;
`;

export const NumberInput = styled.input`
  width: 20px;
  outline: none;
  border: none;
  background: none;
  height: 20px;
  font-size: 0.8rem;
  text-align: center;
  border-bottom: 1px solid var(--gray5);
`;
