import React from "react";
import { RiCloseFill } from "react-icons/ri";
import * as Styles from "./styles";

const Toast = ({ toastlist, setList }) => {
  const deleteToast = React.useCallback(
    (id) => {
      const toastListItem = toastlist.filter((e) => e.id !== id);
      setList(toastListItem);
    },
    [toastlist, setList]
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (toastlist.length) {
        deleteToast(toastlist[0].id);
      }
    }, 4500);

    return () => {
      clearInterval(interval);
    };
  }, [toastlist, deleteToast]);

  const mapToastlistMenu = React.useMemo(() => {
    return toastlist.map(
      (toast: { id: number; title: string }, i: React.Key) => {
        return (
          <Styles.NotificationContainer key={i}>
            <Styles.TextContainer>
              <Styles.Button onClick={() => deleteToast(toast.id)}>
                <RiCloseFill />
              </Styles.Button>

              <Styles.TitleContainer>
                <Styles.Title>{toast.title}</Styles.Title>
              </Styles.TitleContainer>
            </Styles.TextContainer>
          </Styles.NotificationContainer>
        );
      }
    );
  }, [toastlist]);

  return <Styles.Container>{mapToastlistMenu}</Styles.Container>;
};

export default Toast;
