import React from "react";
import { optionsTabsButton, optionsTabsMenu } from "../../utils";
import RenderBadgeValue from "../RenderBadgeValue";
import * as Styles from "./styles";

function Tabs({
  onClickBadgeHigh,
  onClickBadgeMedium,
  onClickBadgeLow,
  setHashTableNumber,
  onChangeTab,
  selected,
}) {
  const [active, setActive] = React.useState<number>(0);

  const ActiveTab = (item, index) => {
    setActive(item.active);
    setHashTableNumber(index);
  };

  const mapOptionsTabsButton = React.useMemo(() => {
    return optionsTabsButton.map((item, index) => {
      return (
        <Styles.TabsButton
          key={index}
          active={active === index}
          activeColor={active === index}
          activeTab={
            item.title === "Em monitoramento" || item.title === "Bloqueados"
          }
          onClick={() => ActiveTab(item, index)}
          onChange={onChangeTab}
          disabled={
            item.title === "Em monitoramento" || item.title === "Bloqueados"
          }
        >
          {item.title}
        </Styles.TabsButton>
      );
    });
  }, [optionsTabsButton, active]);

  const mapOptionsMenu = React.useMemo(() => {
    return optionsTabsMenu.map((item, index) => {
      return <Styles.TextMenuTabs key={index}>{item}</Styles.TextMenuTabs>;
    });
  }, [optionsTabsMenu]);

  return (
    <Styles.TabContainer>
      <Styles.FilterContainer>
        <Styles.BlocTabs>{mapOptionsTabsButton}</Styles.BlocTabs>

        <RenderBadgeValue
          selected={selected}
          alertHigh={0}
          alertMedium={0}
          alertLow={0}
          onClickBadgeHigh={onClickBadgeHigh}
          onClickBadgeMedium={onClickBadgeMedium}
          onClickBadgeLow={onClickBadgeLow}
        />
      </Styles.FilterContainer>

      <Styles.TabsMenuContent>{mapOptionsMenu}</Styles.TabsMenuContent>
    </Styles.TabContainer>
  );
}

export default Tabs;
