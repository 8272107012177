import React from "react";
import ButtonClearFilter from "../../components/ButtonClearFilter";
import ButtonSearch from "../../components/ButtonSearch";
import InputDropDown from "../../components/InputDropDown";
import InputSearch from "../../components/InputSearch";
import * as DateFns from "date-fns";
import * as Styles from "./styles";
import Tabs from "../../components/Tabs";
import Modal from "../../components/Modal";
import InputDate from "../../components/InputDate";
import { filterTypes } from "../../utils";
import allAlertsService from "../../services/allAlertsService";
import { ComplianceContext } from "../../context/complianceContext";
import Table from "../../components/Table";
import Toast from "../../components/Toast";
import Loading from "../../components/Loading";
import { CookieStorage } from "cookie-storage";
import Pagination from "../../components/Pagination";
import updateAlert from "../../services/updateAlert";
import { IErrorRes } from "../../services/allAlertsError.structure";
import ModalBeneficiary from "../../components/ModalBeneficiary";
import ModalInfo from "../../components/ModalInfo";

interface IHashMapModal {
  [key: number]: React.ReactNode;
}

interface IHashFilter {
  [key: string]: string;
}

const Alerts = () => {
  const {
    status,
    setStatus,
    currentPage,
    setCurrentPage,
    flag,
    setFlag,
    setTabValue,
    pageNumber,
    setPageNumber,
  } = React.useContext(ComplianceContext);
  const cookieStorage = new CookieStorage({ path: "/" });
  const { token } = JSON.parse(cookieStorage.getItem("hub_auth"));
  const [loading, setLoading] = React.useState<boolean>(false);
  const [allAlerts, setAllAlerts] = React.useState<any[]>([]);
  const [filterValue, setFilterValue] = React.useState<string>("");
  const [id, setId] = React.useState<string>("");
  const [motive, setMotive] = React.useState<string>("");
  const [valueModalIgnore, setValueModalIgnore] = React.useState<string>("");
  const [pattern, setPattern] = React.useState<string>("");
  const [filterType, setFilterType] = React.useState<string>("document");
  const [selected, setSelected] = React.useState<string>("");
  const [hash, setHash] = React.useState<number>(0);
  const [hashTable, setHashTable] = React.useState<number>(0);
  const [hashErrorToken, setHashErrorToken] = React.useState<number>(0);
  const [hashBeneficiary, setHashBeneficiary] = React.useState<number>(0);
  const [hashTableNumber, setHashTableNumber] = React.useState<number>(0);
  const [nameBeneficiary, setNameBeneficiary] = React.useState<string>("");
  const [accountBeneficiary, setAccountBeneficiary] =
    React.useState<string>("");
  const [bankBeneficiary, setBankBeneficiary] = React.useState<string>("");
  const [branchBeneficiary, setBranchBeneficiary] = React.useState<string>("");
  const [analyser, setAnalyser] = React.useState<string>("");
  const [resolvedAt, setResolvedAt] = React.useState<string>("");
  const [statusIngored, setStatusIngored] = React.useState<string>("");
  const [alertInfoHigh, setAlertInfoHigh] = React.useState<number>(0);
  const [alertInfoMedium, setAlertInfoMedium] = React.useState<number>(0);
  const [alertInfoLow, setAlertInfoLow] = React.useState<number>(0);
  const [documentBeneficiary, setDocumentBeneficiary] =
    React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string>(
    DateFns.format(new Date(2022, 10, 1), "yyyy-MM-dd")
  );
  const [lastDate, setLastDate] = React.useState<string>(
    DateFns.format(DateFns.endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [isError] = React.useState<string>("");
  const [list, setList] = React.useState<Array<string | number>>([]);
  let toastProperties: string | any = [];

  const handleChange = (newValue: number) => {
    setTabValue(newValue);
  };

  const showToast = (isError: string) => {
    if (isError === "alert") {
      toastProperties = {
        id: list.length + 1,
        title: "Erro ao carregar os alertas",
      };
      setList([...list, toastProperties]);
    } else if (isError === "updateAlert") {
      toastProperties = {
        id: list.length + 1,
        title: "Erro ao mudar o status do alerta",
      };
      setList([...list, toastProperties]);
    } else {
      toastProperties = [];
    }
  };

  const mapFilterType = React.useMemo(() => {
    return filterTypes.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }, []);

  const alertUpdate = React.useCallback(() => {
    (async () => {
      setLoading(true);
      const response = await updateAlert.updateAlert(
        id,
        "IGNORED",
        valueModalIgnore
      );
      if (response !== true) {
        showToast("updateAlert");
        setLoading(false);
        return;
      }
      setHashTable(0);
      setHash(0);
      window.location.href = "/hub/compliance-alerts";
      setLoading(false);
    })();
  }, [valueModalIgnore, id]);

  const ModalErrorTokenHash: IHashMapModal = {
    1: (
      <ModalInfo
        closeButton={() => {
          cookieStorage.clear();
          window.location.href = "/login";
        }}
        title="Sua sessão expirou"
        content={
          <Styles.BoxInputsInfo>
            <Styles.InputAreaContainerInfo>
              <Styles.TextContainerInfo>
                <Styles.TitleInfo>
                  Retorne para a
                  <Styles.TitleBoldInfo>Tela de Login</Styles.TitleBoldInfo>{" "}
                  para acessá-la novamente.
                </Styles.TitleInfo>
              </Styles.TextContainerInfo>

              <Styles.ButtonContainerInfo>
                <Styles.ButtonInfo
                  onClick={() => {
                    cookieStorage.clear();
                    window.location.href = "/login";
                  }}
                >
                  Entendido
                </Styles.ButtonInfo>
              </Styles.ButtonContainerInfo>
            </Styles.InputAreaContainerInfo>
          </Styles.BoxInputsInfo>
        }
      />
    ),
  };

  const ModalBeneficiaryHash: IHashMapModal = {
    1: (
      <ModalBeneficiary
        closeButton={() => {
          setHashBeneficiary(0);
        }}
        title="Beneficiário"
        content={
          <Styles.BoxInputsBeneficiary>
            <Styles.InputAreaContainerBeneficiary>
              <Styles.TextContainerBeneficiary>
                <Styles.TitleBeneficiary>Nome:</Styles.TitleBeneficiary>
                <Styles.SubTitleBeneficiary>
                  {nameBeneficiary}
                </Styles.SubTitleBeneficiary>
              </Styles.TextContainerBeneficiary>

              <Styles.TextContainerBeneficiary>
                <Styles.TitleBeneficiary>Documento:</Styles.TitleBeneficiary>
                <Styles.SubTitleBeneficiary>
                  {documentBeneficiary.length === 11
                    ? documentBeneficiary.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                        "$1.$2.$3-$4"
                      )
                    : documentBeneficiary.replace(
                        /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                        "$1 $2 $3/$4-$5"
                      )}
                </Styles.SubTitleBeneficiary>
              </Styles.TextContainerBeneficiary>

              <Styles.TextContainerBeneficiary>
                <Styles.TitleBeneficiary>Banco:</Styles.TitleBeneficiary>
                <Styles.SubTitleBeneficiary>
                  {bankBeneficiary}
                </Styles.SubTitleBeneficiary>
              </Styles.TextContainerBeneficiary>

              <Styles.TextContainerBeneficiary>
                <Styles.TitleBeneficiary>Agência:</Styles.TitleBeneficiary>
                <Styles.SubTitleBeneficiary>
                  {branchBeneficiary}
                </Styles.SubTitleBeneficiary>
              </Styles.TextContainerBeneficiary>

              <Styles.TextContainerBeneficiary>
                <Styles.TitleBeneficiary>Conta:</Styles.TitleBeneficiary>
                <Styles.SubTitleBeneficiary>
                  {accountBeneficiary}
                </Styles.SubTitleBeneficiary>
              </Styles.TextContainerBeneficiary>

              <Styles.ButtonContainerBeneficiary>
                <Styles.ButtonBeneficiary
                  onClick={() => {
                    setHashBeneficiary(0);
                  }}
                >
                  Entendido
                </Styles.ButtonBeneficiary>
              </Styles.ButtonContainerBeneficiary>
            </Styles.InputAreaContainerBeneficiary>
          </Styles.BoxInputsBeneficiary>
        }
      />
    ),
  };

  const ModalButtonsHashMap: IHashMapModal = {
    1: (
      <Modal
        closeButton={() => {
          setHash(0);
        }}
        title="Detalhamento do motivo"
        content={
          <Styles.BoxInputs>
            <Styles.InputAreaContainer>
              <Styles.InputArea
                placeholder="Escreva aqui o motivo que justifique ignorar este usuário"
                value={valueModalIgnore}
                onChange={(e) => {
                  setValueModalIgnore(e.target.value);
                }}
              />
              <Styles.Button
                onClick={alertUpdate}
                disabled={valueModalIgnore.length < 1}
                active={valueModalIgnore.length < 1}
              >
                Ignorar usuário
              </Styles.Button>
            </Styles.InputAreaContainer>
          </Styles.BoxInputs>
        }
      />
    ),
    2: (
      <Modal
        closeButton={() => {
          setHash(0);
        }}
        title="Detalhamento do motivo"
        content={
          <Styles.BoxInputs>
            <Styles.InputAreaContainer>
              <Styles.InputArea
                placeholder="Escreva aqui o motivo que justifique o bloqueio do usuário"
                value={valueModalIgnore}
                onChange={(e) => {
                  setValueModalIgnore(e.target.value);
                }}
              />
              <Styles.ButtonBloc
                onClick={() => {
                  setHashTable(1);
                  setHash(0);
                }}
              >
                Bloquear
              </Styles.ButtonBloc>
            </Styles.InputAreaContainer>
          </Styles.BoxInputs>
        }
      />
    ),
    3: (
      <Modal
        closeButton={() => {
          setHash(0);
        }}
        title="Detalhamento do motivo"
        content={
          <Styles.BoxInputs>
            <Styles.InputAreaContainer>
              <Styles.InputArea
                disabled={statusIngored === "IGNORED"}
                value={motive}
              />
              <Styles.Button
                onClick={() => {
                  setHash(0);
                }}
              >
                Aceitar
              </Styles.Button>
              <Styles.AnalyserContainer>
                {analyser} -{" "}
                {resolvedAt
                  ? DateFns.format(new Date(resolvedAt), "dd/MM/yyyy")
                  : null}
              </Styles.AnalyserContainer>
            </Styles.InputAreaContainer>
          </Styles.BoxInputs>
        }
      />
    ),
  };

  const enumStatus: IHashFilter = {
    0: "OPEN",
    1: "MONITORED",
    2: "IGNORED",
    3: "BLOCKED",
  };

  const HashFilter: IHashFilter = {
    document: `&userDocument=${pattern}`,
    account: `&bankAccount=${pattern}`,
    bank: `&clientName=${pattern}`,
    user: `&userName=${pattern}`,
  };

  const setFilter = React.useCallback(
    (pattern: string): string => {
      if (pattern === "") return "";

      return HashFilter[filterType];
    },
    [pattern]
  );

  const changeFilterType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(event.target.value);
    setPattern("");
    setFilterValue("");
  };

  const regexPattern = React.useCallback(
    (value: string): string => {
      if (filterType === "document") {
        return value.replace(/\D/g, "");
      }
      return value;
    },
    [filterType]
  );

  React.useEffect(() => {
    setStatus(enumStatus[hashTableNumber]);
  }, [hashTableNumber]);

  React.useEffect(() => {
    setTabValue(0);
  }, []);

  React.useEffect(() => {
    if (selected === "HIGH") {
      setFlag("&flag=HIGH");
    } else if (selected === "MEDIUM") {
      setFlag("&flag=MEDIUM");
    } else if (selected === "LOW") {
      setFlag("&flag=LOW");
    } else if (selected === "") {
      setFlag("");
    }
  }, [selected, flag]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);

      const alerts = await allAlertsService.getAllAlerts(
        currentPage.toString(),
        status,
        setFilter(pattern),
        flag,
        startDate,
        lastDate
      );

      if (!alerts) {
        showToast("alert");
        setLoading(false);
      }

      if ((alerts as IErrorRes).message || !token) {
        setLoading(false);
        setHashErrorToken(1);
        return;
      }

      if (alerts) {
        setAllAlerts(alerts.alerts);
        setPageNumber(alerts.pages);
        setAlertInfoHigh(alerts.alertsInfo.high);
        setAlertInfoMedium(alerts.alertsInfo.medium);
        setAlertInfoLow(alerts.alertsInfo.low);
        setLoading(false);
        return;
      }

      setLoading(false);
      setCurrentPage(1);
    })();
  }, [currentPage, status, flag, isError, startDate, lastDate, pattern]);

  return (
    <Styles.MainContainer>
      <Styles.SubContainer>
        <Styles.TitleContainer>
          <Styles.TitleSubContainer>
            <Styles.HeaderContainer>
              <Styles.Title>Alertas Compliance</Styles.Title>
              <Styles.SubTitle>Utilize o filtro para pesquisar</Styles.SubTitle>
            </Styles.HeaderContainer>

            <Styles.DataContainer>
              <InputDate
                valueInitialDate={startDate}
                onChangeInitialDate={(e) => {
                  setStartDate(e.target.value);
                }}
                valueLastDate={lastDate}
                onChangeLastDate={(e) => {
                  setLastDate(e.target.value);
                }}
              />
            </Styles.DataContainer>
          </Styles.TitleSubContainer>
        </Styles.TitleContainer>

        <Styles.InputContainer>
          <Styles.InputSubContainer>
            <InputDropDown
              content={mapFilterType}
              value={filterType}
              onChange={changeFilterType}
            />

            <InputSearch
              placeholder="Buscar"
              value={filterValue}
              onChange={(e) => setFilterValue(regexPattern(e.target.value))}
            />

            <ButtonSearch onClick={() => setPattern(filterValue)} />

            <Styles.ButtonClearFilterContainer>
              <ButtonClearFilter
                title="Limpar Filtro"
                onClick={() => {
                  setPattern("");
                  setFilterValue("");
                }}
              />
            </Styles.ButtonClearFilterContainer>
          </Styles.InputSubContainer>
        </Styles.InputContainer>

        <Tabs
          selected={selected}
          onChangeTab={handleChange}
          setHashTableNumber={setHashTableNumber}
          onClickBadgeHigh={() => {
            if (selected === "HIGH") {
              setSelected("");
            } else {
              setSelected("HIGH");
            }
          }}
          onClickBadgeMedium={() => {
            if (selected === "MEDIUM") {
              setSelected("");
            } else {
              setSelected("MEDIUM");
            }
          }}
          onClickBadgeLow={() => {
            if (selected === "LOW") {
              setSelected("");
            } else {
              setSelected("LOW");
            }
          }}
        />

        <Styles.AccordionContent>
          {loading ? (
            <Styles.ContainerLoading>
              <Loading />
            </Styles.ContainerLoading>
          ) : (
            <Table
              setId={setId}
              setStatusIngored={setStatusIngored}
              setAnalyser={setAnalyser}
              setResolvedAt={setResolvedAt}
              setMotive={setMotive}
              setNameBeneficiary={setNameBeneficiary}
              setAccountBeneficiary={setAccountBeneficiary}
              setBankBeneficiary={setBankBeneficiary}
              setBranchBeneficiary={setBranchBeneficiary}
              setDocumentBeneficiary={setDocumentBeneficiary}
              TableValue={allAlerts}
              hashTable={hashTable}
              onClickBeneficiary={() => setHashBeneficiary(1)}
              closeButton={() => {
                setHashTable(0);
              }}
              onClick={() => setHashTable(1)}
              onClickButtonIgnore={() => setHash(1)}
              onClickToMonitor={() => {}}
              onClickBlock={() => setHash(2)}
              onClickButtonAnalyser={() => setHash(3)}
            />
          )}
        </Styles.AccordionContent>

        <Styles.PaginationContent>
          {!loading && (
            <Pagination
              offset={currentPage}
              setOffset={setCurrentPage}
              total={pageNumber}
            />
          )}
        </Styles.PaginationContent>
      </Styles.SubContainer>

      {ModalButtonsHashMap[hash]}

      {ModalBeneficiaryHash[hashBeneficiary]}

      {ModalErrorTokenHash[hashErrorToken]}

      <Toast toastlist={list} setList={setList} />
    </Styles.MainContainer>
  );
};

export default Alerts;
