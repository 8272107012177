import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  width: 90%;
  max-width: 30rem;
  height: 20rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.5rem;
  padding: 1.563rem 0 2.438rem 0;
  background-color: #ffffff;
`;
export const Header = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  padding: 0.375rem 0;
`;

export const Title = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 700;
  color: var(--gray5);
`;

export const CloseButton = styled.button`
  display: flex;
  font-size: 2rem;
  background: none;
  font-weight: bold;
  color: var(--error);
  cursor: pointer;
  outline: none;
  border: none;
  width: max-content;
`;
