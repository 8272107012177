import { IInputSearch } from "./inputSearch.structore";
import * as Styles from "./styles";

const InputSearch = ({ placeholder, value, onChange }: IInputSearch) => {
  return (
    <div>
      <Styles.SearchInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputSearch;
