import { IButtonClearFilter } from "./buttonClearFilter.structure";
import * as Styles from "./styles";

const ButtonClearFilter = ({ onClick, title }: IButtonClearFilter) => {
  return (
    <div>
      <Styles.ClearFilterButton type="button" onClick={onClick}>
        {title}
      </Styles.ClearFilterButton>
    </div>
  );
};

export default ButtonClearFilter;
