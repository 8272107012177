import axios from "axios";
import * as Interface from "./allAlertsError.structure";
import api from "./api";

class AllAlertsService {
  /**
   * @description Get all alerts
   * @param {string} currentPage return the current page
   * @param {string} status OPEN, MONITORED, BLOCKED, IGNORED
   * @param {string} filter userDocument, bankAccount, clientName, userName
   * @param {string} flag HIGH, MEDIUM, LOW
   * @param {string} firstDate filter on deployment date
   * @param {string} lastDate filter on last day on month
   * @returns {Promise<object | false>} An object when requisition is true or false in error
   */

  async getAllAlerts(
    currentPage: string,
    status: string,
    filter: string,
    flag: string,
    firstDate: string,
    lastDate: string
  ) {
    try {
      const {data} = await api.get(
        `alerts?limit=8&offset=${currentPage}&status=${status}${filter}&startDate=${firstDate}&endDate=${lastDate}${flag}`
      );
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes: Interface.IErrorRes | unknown = error.response?.data;
        return errorRes as Interface.IErrorRes;
      }
    }
    return false;
  }
}

export default new AllAlertsService();
