import * as Styles from "./styles";
import { GrSearch } from "react-icons/gr";
import { IButtonSearch } from "./buttonSearch.structore";

const ButtonSearch = ({ onClick }: IButtonSearch) => {
  return (
    <div>
      <Styles.SearchButton type="button" onClick={onClick}>
        <Styles.Icon>
          <GrSearch />
        </Styles.Icon>
      </Styles.SearchButton>
    </div>
  );
};

export default ButtonSearch;
