import styled from "styled-components";

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8vh;
  align-items: center;
  justify-content: center;
  gap: 120px;
  background: var(--gray1);

  @media only screen and (max-width: 1400px) {
    height: 10vh;
    gap: 120px;
  }
`;

export const BoxMenuContent = styled.div`
  font-size: 1em;
  font-weight: 700;
  color: var(--gray4);
`;

export const BoxValueContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const MessageText = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--gray3);
`;
