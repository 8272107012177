import styled from "styled-components";

export const AlertContainerHigh = styled.div`
  margin-bottom: 20px;
  margin-left: 18px;
  margin-right: -42px;
`;
export const AlertContainerMedium = styled.div`
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: -42px;
`;

export const AlertContainerLow = styled.div`
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: -40px;
`;
export const AlertHigh = styled.div`
  display: flex;
  background: none;
  width: 6rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid var(--error);
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: var(--error);
  cursor: pointer;
`;
export const BadgeHigh = styled.div`
  display: flex;
  width: 1.2rem;
  height: 1.1rem;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.15rem solid var(--error);
  background: var(--error);
  color:  #ffffff;
`;

export const AlertMedium = styled.div`
  display: flex;
  background: none;
  width: 6rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid #fcbe07;
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: #fcbe07;
  cursor: pointer;
`;
export const BadgeMedium = styled.div`
  display: flex;
  width: 1.2rem;
  height: 1.1rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.15rem solid #fcbe07;
  background-color: #fcbe07;
  color:#ffffff;
`;

export const AlertLow = styled.div`
  display: flex;
  background: none;
  width: 6rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid var(--success);
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: var(--success);
  cursor: pointer;
`;
export const BadgeLow = styled.div`
  display: flex;
  width: 1.2rem;
  height: 1.1rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.15rem solid var(--success);
  background: var(--success);
  color:  #ffffff;
`;
