import styled from "styled-components";

export const ClearFilterButton = styled.button`
  width: 150px;
  height: 40px;
  background: white;
  border: 1px dashed var(--gray2);
  border-radius: 0.3rem;
  font-size: 1em;
  font-weight: 400;
  color: var(--gray4);
  cursor: pointer;
  &:hover {
    outline: 1px dashed var(--primary);
  }
`;
