import styled from "styled-components";

export const SearchButton = styled.button`
  width: 50px;
  height: 50px;
  padding: 15px 10px;
  background: white;
  border: 1px dashed var(--gray2);
  border-radius: 50px;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    outline: 1px dashed var(--primary);
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
