import * as Styles from "./styles";

interface IHashMap {
  [key: string]: React.ReactNode;
}

const renderFlags = (priority, value): JSX.Element => {
  const RenderFlagsHashMap: IHashMap = {
    HIGH: (
      <Styles.AlertHigh>
        Alta
        {value > 0 && (
          <Styles.AlertContainerHigh>
            <Styles.BadgeHigh>{value}</Styles.BadgeHigh>
          </Styles.AlertContainerHigh>
        )}
      </Styles.AlertHigh>
    ),
    MEDIUM: (
      <Styles.AlertMedium>
        Média
        {value > 0 && (
          <Styles.AlertContainerMedium>
            <Styles.BadgeMedium>{value}</Styles.BadgeMedium>
          </Styles.AlertContainerMedium>
        )}
      </Styles.AlertMedium>
    ),
    LOW: (
      <Styles.AlertLow>
        Baixa
        {value > 0 && (
          <Styles.AlertContainerLow>
            <Styles.BadgeLow>{value}</Styles.BadgeLow>
          </Styles.AlertContainerLow>
        )}
      </Styles.AlertLow>
    ),
  };

  return <div>{RenderFlagsHashMap[priority]}</div>;
};
export default renderFlags;
