import axios from "axios";
import { IErrorRes } from "./allAlertsError.structure";

import api from "./api";

class UpdateAlert {
  /**
   * @description Patch an specific alerts and edit it
   * @param {string} id alert.id
   * @param {string} status OPEN, MONITORED, BLOCKED, IGNORED
   * @param {string} reason rason from modalCompliance
   * @returns {Promise} True requisition is true or false in error
   */
  async updateAlert(
    id: string,
    newStatus: string | undefined,
    newDetailing_the_reason: string | undefined
  ): Promise<string | boolean> {
    const object = {
      alertStatus: newStatus,
      details: newDetailing_the_reason,
    };
    try {
      await api.post(`admin/analyze/${id}`, object);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes: IErrorRes | unknown = error.message;
        return errorRes as string;
      }
      return false;
    }
  }
}

export default new UpdateAlert();
