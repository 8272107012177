import styled from "styled-components";

export const DateContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const DateSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DateLabel = styled.label`
  font-size: 0.8em;
  font-weight: 400;
  margin-bottom: 5px;
`;

export const DateInput = styled.input`
  display: flex;
  width: 100%;
  height: 35px;
  padding: 10px 5px;
  font-size: 16px;
  border: 1px solid var(--gray2);
  &:focus {
    outline: 1px solid var(--primary);
  }
`;
