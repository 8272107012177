import styled from "styled-components";

export const AlertContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const BadgeHighContainer = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
`;
